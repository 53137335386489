import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import LoginSitePage from '../../components/LoginSitePage';
import '../../web-common/styles/theme-button.scss';
import PublicApiError from '../../web-common/models/errors/PublicApiError';
import VuplexApi from '../../web-common/client/VuplexApi';
import CredentialsForm from '../../components/CredentialsForm';
import parseQueryString from '../../web-common/utils/parseQueryString';
import './styles.scss';

const STORE_CHECKOUT_URL_REGEX = /(vuplex\.com|localhost:[0-9]+)\/checkout/;

export default class LoginPage extends Component {

  state = {
    isLoading: false,
    error: ''
  }

  render() {
    return (
      <LoginSitePage className="login-page">
        <Helmet>
          <title>Login | Vuplex</title>
          <meta name="description" content="Login to your Vuplex account" />
        </Helmet>
        {this._renderMessage()}
        <CredentialsForm
          buttonText="Log In"
          onSubmit={this._handleSubmit}
          isLoading={this.state.isLoading}
          error={this.state.error}>
          <a href="/request-password-reset">Forgot Password?</a>
          <hr/>
          <button
            onClick={this._handleNewAccountButtonClicked}
            className="theme-button new-account-button">
            Create New Account
          </button>
        </CredentialsForm>
      </LoginSitePage>
    );
  }

  private _getReturnUrl() {

    let queryString = '';
    // Try-catch required for node prerendering because location
    // doesn't exist in node.
    try {
      queryString = location.search;
    } catch (error) {}

    return parseQueryString(queryString)['returnUrl'] || 'https://dashboard.vuplex.com';
  }

  private _handleNewAccountButtonClicked = () => {

    location.href = '/signup' + location.search;
  };

  private _handleSubmit = async (email: string, password: string) => {
    try {
      this.setState({ isLoading: true, error: '' });
      await VuplexApi.authenticate(email, password);

      const returnUrl = this._getReturnUrl();
      location.href = returnUrl;
    } catch (error) {
      const errorMessage = (error instanceof PublicApiError && error.userMessage) || `An unexpected error occurred: ${error.message}`;
      this.setState({
        isLoading: false,
        error: errorMessage
      });
    }
  };

  private _renderMessage() {

    const returnUrl = this._getReturnUrl();
    if (STORE_CHECKOUT_URL_REGEX.test(returnUrl)) {
      return <h2>Please log in to complete your purchase</h2>;
    }
  }
}
