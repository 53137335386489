import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import VuplexApi from '../../web-common/client/VuplexApi';
import LoginSitePage from '../../components/LoginSitePage';
import PublicApiError from '../../web-common/models/errors/PublicApiError';
import parseQueryString from '../../web-common/utils/parseQueryString';
import CredentialsForm from '../../components/CredentialsForm';
import Urls from '../../web-common/config/Urls';
import './styles.scss';

export default class CompletePasswordResetPage extends Component {

  state = {
    isLoading: false,
    error: '',
    resetComplete: false
  }

  componentDidMount() {

    const { userId, accountId, token } = parseQueryString(location.search);
    if (userId && accountId && token) {
      this._userId = userId;
      this._accountId = accountId;
      this._token = token;
    } else {
      // URL doesn't include the required query params, so redirect to login.
      location.href = "/login";
    }
  }

  render() {
    return (
      <LoginSitePage className="complete-password-reset">
        <Helmet>
          <title>Password Reset | Vuplex</title>
          <meta name="description" content="Reset your Vuplex account password" />
        </Helmet>
        {this._renderContent()}
      </LoginSitePage>
    );
  }

  private _accountId = '';
  private _token = '';
  private _userId = '';

  private _handleSubmit = async (_, newPassword) => {

    this.setState({ isLoading: true });
    try {
      await VuplexApi.fetchAndAssertOk(`/accounts/${this._accountId}/users/${this._userId}/password-reset`, {
        method: 'post',
        body: JSON.stringify({
          token: this._token,
          newPassword
        })
      });
      this.setState({
        resetComplete: true,
        isLoading: false
      });
    } catch (error) {
      const errorMessage = (error instanceof PublicApiError && error.userMessage) || `An unexpected error occurred: ${error.message}`;
      this.setState({
        isLoading: false,
        error: errorMessage
      });
    }
  };

  private _renderContent() {

    if (this.state.resetComplete) {
      return (
        <div className="completion-message">
          <h1>You're all done!</h1>
          <p>
            Your new password has been set. Now you can  <a href={Urls.Dashboard}>continue to your dashboard</a>.
          </p>
        </div>
      );
    }

    return (
      <CredentialsForm
        buttonText="Set Password"
        passwordPlaceholder="New password"
        omitEmail={true}
        onSubmit={this._handleSubmit}
        isLoading={this.state.isLoading}
        error={this.state.error}/>
    )
  }
}
