import React, { Component } from 'react';
import Spinner from '../../web-common/components/Spinner';
import isValidEmailAddress from '../../web-common/utils/isValidEmailAddress';
import '../../web-common/styles/theme-button.scss';
import './styles.scss';

interface CredentialsFormProps {
  onSubmit: (email: string, password: string) => void;
  error: string;
  isLoading: boolean;
  buttonText: string;
  passwordPlaceholder?: string;
  omitEmail?: boolean;
  omitPassword?: boolean;
}

export default class CredentialsForm extends Component<CredentialsFormProps> {

  state = {
    email: '',
    password: ''
  };

  render() {

    if (this.props.isLoading) {
      return <Spinner className="credentials-form-spinner" />
    }

    return (
      <section className="credentials-form">
        <div>
          {!this.props.omitEmail && <input type="email" placeholder="Email" value={this.state.email} onChange={this._handleEmailChange} ref={this._handleEmailRef} />}
          {!this.props.omitPassword && <input type="password" placeholder={this.props.passwordPlaceholder || "Password"} value={this.state.password} onKeyDown={this._handlePasswordKeyDown} onChange={this._handlePasswordChange} ref={this._handlePasswordRef} />}
          {this._renderError()}
          <button
            onClick={this._emitSubmit}
            disabled={!this._inputsAreValid}
            className="theme-button main-button">
            {this.props.buttonText}
          </button>
          {this.props.children}
        </div>
      </section>
    );
  }

  private _emitSubmit = () => {

    this.props.onSubmit(this.state.email, this.state.password);
  };

  private _handleEmailChange = (event) => this.setState({ email: event.target.value, error: '' });

  private _handlePasswordChange = (event) => this.setState({ password: event.target.value, error: '' });

  private _handlePasswordKeyDown = (event) => {

    // Submit the search when Enter is pressed.
    if (event.keyCode === 13 && this._inputsAreValid) {
      this._emitSubmit();
    }
  };

  // On Firefox specifically, the onchange event isn't fired when inputs are filled via password autofill.
  // So, to prevent the button from being incorrectly disabled, we need to initialize the state with the inputs' initial values.
  private _handleEmailRef = (element: HTMLInputElement) => element && this.setState({ email: element.value });
  private _handlePasswordRef = (element: HTMLInputElement) => element && this.setState({ password: element.value });

  private get _inputsAreValid() {

    return (this.props.omitEmail || isValidEmailAddress(this.state.email)) && (this.props.omitPassword || this.state.password.trim().length);
  }

  private _renderError() {

    if (!this.props.error) {
      return;
    }
    return (
      <div className="error">
        {this.props.error}
      </div>
    );
  }
}
