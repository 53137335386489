import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import '../../web-common/styles/theme-button.scss';
import LoginSitePage from '../../components/LoginSitePage';
import PublicApiError from '../../web-common/models/errors/PublicApiError';
import parseQueryString from '../../web-common/utils/parseQueryString';
import VuplexApi from '../../web-common/client/VuplexApi';
import CredentialsForm from  '../../components/CredentialsForm';
import './styles.scss';

export default class SignupPage extends Component {

  state = {
    isLoading: false,
    error: ''
  }

  render() {
    return (
      <LoginSitePage className="signup-page">
        <Helmet>
          <title>Signup | Vuplex</title>
          <meta name="description" content="Signup for a Vuplex account" />
        </Helmet>
        <CredentialsForm
          buttonText="Create New Account"
          passwordPlaceholder="New password"
          onSubmit={this._handleSubmit}
          isLoading={this.state.isLoading}
          error={this.state.error}>
          <div className="policy">
            By clicking Create New Account, you agree to our <a href="https://support.vuplex.com/articles/privacy">privacy policy</a>.
          </div>
        </CredentialsForm>
      </LoginSitePage>
    );
  }

  private async _createAccount(email: string, password: string) {

    await VuplexApi.fetchAndAssertOk('/accounts', {
      method: 'post',
      body: JSON.stringify({ email, password })
    });
  }

  private _handleSubmit = async (email: string, password: string) => {
    try {
      this.setState({ isLoading: true, error: '' });
      await this._createAccount(email, password);
      await VuplexApi.authenticate(email, password);

      const returnUrl = parseQueryString(location.search)['returnUrl'] || 'https://dashboard.vuplex.com';
      location.href = returnUrl;
    } catch (error) {
      const errorMessage = (error instanceof PublicApiError && error.userMessage) || `An unexpected error occurred: ${error.message}`;
      this.setState({
        isLoading: false,
        error: errorMessage
      });
    }
  };
}
