import React, { Component } from 'react';
import LoginNavbar from '../LoginNavbar';
import '../../index.scss';
import './styles.scss';

interface LoginSitePageProps {
  className?: string;
}

export default class LoginSitePage extends Component<LoginSitePageProps> {

  render() {
    return (
      <div className={`login-site-page`}>
        <LoginNavbar />
        <div className={this.props.className}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
