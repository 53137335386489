import React from 'react';
import Navbar from '../../web-common/components/Navbar';
import './styles.scss';

const LoginNavbar = () => (
  <Navbar
    siteName="login"
    className="login-navbar"
    centerLinks={[
      <a key="store" href="https://store.vuplex.com">store</a>,
      <a key="dev" href="https://developer.vuplex.com">developers</a>,
      <a key="support" href="https://support.vuplex.com">support</a>,
    ]}/>
);

export default LoginNavbar;
