import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import VuplexApi from '../../web-common/client/VuplexApi';
import LoginSitePage from '../../components/LoginSitePage';
import PublicApiError from '../../web-common/models/errors/PublicApiError';
import CredentialsForm from '../../components/CredentialsForm';
import './styles.scss';

export default class RequestPasswordResetPage extends Component {

  state = {
    isLoading: false,
    error: '',
    requestSent: false
  }

  render() {
    return (
      <LoginSitePage className="request-password-reset">
        <Helmet>
          <title>Password Reset | Vuplex</title>
          <meta name="description" content="Reset your Vuplex account password" />
        </Helmet>
        {this._renderContent()}
      </LoginSitePage>
    );
  }

  private _handleSubmit = async (email) => {

    this.setState({ isLoading: true });
    try {
      await VuplexApi.fetchAndAssertOk(`/accounts/password-reset`, {
        method: 'post',
        body: JSON.stringify({ email })
      });
      this.setState({
        requestSent: true,
        isLoading: false
      });
    } catch (error) {
      const errorMessage = (error instanceof PublicApiError && error.userMessage) || `An unexpected error occurred: ${error.message}`;
      this.setState({
        isLoading: false,
        error: errorMessage
      });
    }
  };

  private _renderContent() {

    if (this.state.requestSent) {
      return (
        <div className="completion-message">
          <h1>We just sent you a password reset email</h1>
          <p>
            Please check your inbox and click the email's link to reset your password.
          </p>
        </div>
      );
    }

    return (
      <CredentialsForm
        buttonText="Request password reset"
        omitPassword={true}
        onSubmit={this._handleSubmit}
        isLoading={this.state.isLoading}
        error={this.state.error}/>
    )
  }
}
