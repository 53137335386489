import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import VuplexApi from '../../web-common/client/VuplexApi';
import parseQueryString from '../../web-common/utils/parseQueryString';
import getApiErrorForResponse from '../../web-common/utils/getApiErrorForResponse';
import Urls from '../../web-common/config/Urls';
import Spinner from '../../web-common/components/Spinner';
import PageError from '../../web-common/components/PageError';
import checkmarkImage from './assets/checkmark.svg';
import './styles.scss';
import LoginSitePage from '../../components/LoginSitePage';

export default class EmailVerificationPage extends Component {

  state = {
    error: '',
    complete: true
  }

  async componentDidMount() {

    const { email, code } = parseQueryString(location.search);
    if (!(email && code)) {
      this.setState({ error: 'The email address or code is missing from the URL.' });
      return;
    }
    try {
      await this._verifyEmail(email, code);
      this.setState({ complete: true });
    } catch (error) {
      this.setState({ error: error.message });
    }
  }

  render() {

    return (
      <LoginSitePage className="email-verify-page">
        <Helmet>
          <title>Verify Email | Vuplex</title>
          <meta name="description" content="Verify your email address for your Vuplex account" />
        </Helmet>
        {this._renderContent()}
      </LoginSitePage>
    );
  }

  private _renderContent() {

    if (this.state.error) {
      return <PageError error={this.state.error} />;
    }
    if (!this.state.complete) {
      return <Spinner style={{ margin: '40vh auto' }} />;
    }

    return (
      <div className="success">
        <div>
          <div className="checkmark">
            <img src={checkmarkImage} alt="check mark"/>
          </div>
          <div className="success-text">
            <p>
              Your email is verified
            </p>
            <a href={Urls.Dashboard}>Proceed to dashboard →</a>
          </div>
        </div>
      </div>
    );
  }

  async _verifyEmail(emailAddress: string, emailVerificationCode: string) {

    const response = await VuplexApi.fetch("/accounts/email-verification", {
      method: 'POST',
      body: JSON.stringify({ email: emailAddress, code: emailVerificationCode })
    });
    // 409 indicates that the email is already verified.
    if (!(response.ok || response.status === 409)) {
      throw await getApiErrorForResponse(response);
    }
    return response;
  }
}
